import Icons from 'features/shared/components/icons';
import { CLUB_ATTRIBUTE_ICONS } from '../../../../contentful/constants';

interface IIconMap {
  [key: string]: JSX.Element | null;
}

export const CLUB_ATTRIBUTE_ICON_MAP: IIconMap = {
  [CLUB_ATTRIBUTE_ICONS.NONE]: null,
  [CLUB_ATTRIBUTE_ICONS.ACCESSIBILITY]: <Icons.AccessibilitySmall />,
  [CLUB_ATTRIBUTE_ICONS.AIRCON]: <Icons.AirconSmall />,
  [CLUB_ATTRIBUTE_ICONS.CARDIO]: <Icons.CardioSmall />,
  [CLUB_ATTRIBUTE_ICONS.CITY_CENTER]: <Icons.CityCenterSmall />,
  [CLUB_ATTRIBUTE_ICONS.COACHING]: <Icons.CoachingSmall />,
  [CLUB_ATTRIBUTE_ICONS.COFFEE]: <Icons.CoffeeSmall />,
  [CLUB_ATTRIBUTE_ICONS.DAYCARE]: <Icons.DaycareSmall />,
  [CLUB_ATTRIBUTE_ICONS.DIETITIAN]: <Icons.DietitianSmall />,
  [CLUB_ATTRIBUTE_ICONS.FEMALE]: <Icons.FemaleSmall />,
  [CLUB_ATTRIBUTE_ICONS.MALE]: <Icons.MaleSmall />,
  [CLUB_ATTRIBUTE_ICONS.GROUP]: <Icons.GroupSmall />,
  [CLUB_ATTRIBUTE_ICONS.LOCATION]: <Icons.LocationSmall />,
  [CLUB_ATTRIBUTE_ICONS.NEW_EQUIPMENT]: <Icons.NewEquipmentSmall />,
  [CLUB_ATTRIBUTE_ICONS.OUTDOOR]: <Icons.OutdoorSmall />,
  [CLUB_ATTRIBUTE_ICONS.PARKING]: <Icons.ParkingSmall />,
  [CLUB_ATTRIBUTE_ICONS.PHYSIO]: <Icons.PhysioSmall />,
  [CLUB_ATTRIBUTE_ICONS.POWER]: <Icons.PowerSmall />,
  [CLUB_ATTRIBUTE_ICONS.SAUNA]: <Icons.SaunaSmall />,
  [CLUB_ATTRIBUTE_ICONS.SHOWERS]: <Icons.ShowersSmall />,
  [CLUB_ATTRIBUTE_ICONS.SPACIOUS]: <Icons.SpaciousSmall />,
  [CLUB_ATTRIBUTE_ICONS.SPINNING]: <Icons.SpinningSmall />,
  [CLUB_ATTRIBUTE_ICONS.SQUASH]: <Icons.SquashSmall />,
  [CLUB_ATTRIBUTE_ICONS.TRAIN_STATION]: <Icons.TrainStationSmall />,
  [CLUB_ATTRIBUTE_ICONS.UNIQUE_BUILDING]: <Icons.UniqueBuildingSmall />,
  [CLUB_ATTRIBUTE_ICONS.VIRTUAL]: <Icons.VirtualSmall />,
  [CLUB_ATTRIBUTE_ICONS.YOGA]: <Icons.YogaSmall />,
};

export const CLUB_ATTRIBUTE_LARGE_ICON_MAP: IIconMap = {
  [CLUB_ATTRIBUTE_ICONS.NONE]: null,
  [CLUB_ATTRIBUTE_ICONS.ACCESSIBILITY]: <Icons.AccessibilityLarge />,
  [CLUB_ATTRIBUTE_ICONS.AIRCON]: <Icons.AirconLarge />,
  [CLUB_ATTRIBUTE_ICONS.CARDIO]: <Icons.CardioLarge />,
  [CLUB_ATTRIBUTE_ICONS.CITY_CENTER]: <Icons.CityCenterLarge />,
  [CLUB_ATTRIBUTE_ICONS.COACHING]: <Icons.CoachingLarge />,
  [CLUB_ATTRIBUTE_ICONS.COFFEE]: <Icons.CoffeeLarge />,
  [CLUB_ATTRIBUTE_ICONS.DAYCARE]: <Icons.DaycareLarge />,
  [CLUB_ATTRIBUTE_ICONS.DIETITIAN]: <Icons.DietitianLarge />,
  [CLUB_ATTRIBUTE_ICONS.FEMALE]: <Icons.FemaleLarge />,
  [CLUB_ATTRIBUTE_ICONS.MALE]: <Icons.MaleLarge />,
  [CLUB_ATTRIBUTE_ICONS.GROUP]: <Icons.GroupLarge />,
  [CLUB_ATTRIBUTE_ICONS.LOCATION]: <Icons.LocationLarge />,
  [CLUB_ATTRIBUTE_ICONS.NEW_EQUIPMENT]: <Icons.NewEquipmentLarge />,
  [CLUB_ATTRIBUTE_ICONS.OUTDOOR]: <Icons.OutdoorLarge />,
  [CLUB_ATTRIBUTE_ICONS.PARKING]: <Icons.ParkingLarge />,
  [CLUB_ATTRIBUTE_ICONS.PHYSIO]: <Icons.PhysioLarge />,
  [CLUB_ATTRIBUTE_ICONS.POWER]: <Icons.PowerLarge />,
  [CLUB_ATTRIBUTE_ICONS.SAUNA]: <Icons.SaunaLarge />,
  [CLUB_ATTRIBUTE_ICONS.SHOWERS]: <Icons.ShowersLarge />,
  [CLUB_ATTRIBUTE_ICONS.SPACIOUS]: <Icons.SpaciousLarge />,
  [CLUB_ATTRIBUTE_ICONS.SPINNING]: <Icons.SpinningLarge />,
  [CLUB_ATTRIBUTE_ICONS.SQUASH]: <Icons.SquashLarge />,
  [CLUB_ATTRIBUTE_ICONS.TRAIN_STATION]: <Icons.TrainStationLarge />,
  [CLUB_ATTRIBUTE_ICONS.UNIQUE_BUILDING]: <Icons.UniqueBuildingLarge />,
  [CLUB_ATTRIBUTE_ICONS.VIRTUAL]: <Icons.VirtualLarge />,
  [CLUB_ATTRIBUTE_ICONS.YOGA]: <Icons.YogaLarge />,
};

export default CLUB_ATTRIBUTE_ICON_MAP;
